import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={58}
    fill="none"
    viewBox="0 0 33 58"
    {...props}
  >
    <path
      fill={props.fill || "#636566"}
      fillRule="evenodd"
      d="M31.747 7.373a4.349 4.349 0 0 0 0-6.108 4.249 4.249 0 0 0-6.05 0L1.253 25.945a4.349 4.349 0 0 0 0 6.11l24.444 24.68a4.249 4.249 0 0 0 6.05 0 4.349 4.349 0 0 0 0-6.108L10.316 28.988 31.747 7.373Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
